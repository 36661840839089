import React from 'react'
import { Link } from 'gatsby'

import Seo from '../components/seo'

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>PAGE NOT FOUND</h1>
  <p>Very sorry - but that page doesn't exist, or you do not have access to it.
  <br /><u><Link to="/" style={{color: `#ffffff` }}>Back to the home page.</Link></u>
  </p>
  </>
)

export default NotFoundPage
